@import "/libs/shared/src/styles/variables.scss";

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
  // custom variables
  --app-border-width: 2px;
  --app-border-radius: 8px;

  // general
  --ion-background-color: var(--ion-color-white);
  --ion-background-color-rgb: var(--ion-color-white-rgb);
  --ion-text-color: var(--ion-color-dark);
  --ion-text-color-rgb: var(--ion-color-dark-rgb);
  --ion-backdrop-color: var(--ion-color-medium);
  --ion-backdrop-opacity: 0.42;
  --ion-overlay-background-color: var(--ion-color-white);
  // --ion-border-color: rgba(var(--ion-color-primary-rgb), 0.02);
  --ion-border-color: var(--ion-color-light);
  --ion-box-shadow-color: transparent;
  --ion-toolbar-background: transparent;
  --ion-toolbar-border-color: var(--ion-border-color);
  --ion-toolbar-color: var(--ion-text-color);
  --ion-item-background: transparent;
  --ion-item-color: var(--ion-text-color);
  --ion-item-border-color: var(--ion-border-color);
  --ion-placeholder-color: var(--ion-color-medium);

  --ion-margin: 16px;
  --ion-padding: 16px;

  // fonts
  --ion-font-size: 16px;
  --ion-font-family: "Roboto", sans-serif;

  // grid
  --ion-grid-padding: 0px;
  --ion-grid-column-padding: 0px;
  --ion-grid-width-xs: 100%;
  --ion-grid-width-sm: 540px;
  --ion-grid-width-md: 720px;
  --ion-grid-width-lg: 960px;
  --ion-grid-width-xl: 1140px;

  // colors
  --ion-color-step-50: #f2f4f4;
  --ion-color-step-100: #e6e8e9;
  --ion-color-step-150: #d9ddde;
  --ion-color-step-200: #ccd2d3;
  --ion-color-step-250: #bfc6c8;
  --ion-color-step-300: #b3bbbd;
  --ion-color-step-350: #a6b0b2;
  --ion-color-step-400: #99a4a7;
  --ion-color-step-450: #8c999c;
  --ion-color-step-500: #808e91;
  --ion-color-step-550: #738285;
  --ion-color-step-600: #66777a;
  --ion-color-step-650: #596b6f;
  --ion-color-step-700: #4d6064;
  --ion-color-step-750: #405559;
  --ion-color-step-800: #33494e;
  --ion-color-step-850: #263e43;
  --ion-color-step-900: #193338;
  --ion-color-step-950: #0d272d;

  --ion-color-primary: #013b4b;
  --ion-color-primary-rgb: 1, 59, 75;
  --ion-color-primary-contrast: var(--ion-color-white);
  --ion-color-primary-contrast-rgb: var(--ion-color-white-rgb);
  --ion-color-primary-shade: #013442;
  --ion-color-primary-tint: #1a4f5d;

  --ion-color-secondary: #41bea3;
  --ion-color-secondary-rgb: 65, 190, 163;
  --ion-color-secondary-contrast: var(--ion-color-white);
  --ion-color-secondary-contrast-rgb: var(--ion-color-white-rgb);
  --ion-color-secondary-shade: #39a78f;
  --ion-color-secondary-tint: #54c5ac;

  --ion-color-tertiary: #f55531;
  --ion-color-tertiary-rgb: 245, 85, 49;
  --ion-color-tertiary-contrast: var(--ion-color-white);
  --ion-color-tertiary-contrast-rgb: var(--ion-color-white-rgb);
  --ion-color-tertiary-shade: #d84b2b;
  --ion-color-tertiary-tint: #f66646;

  --ion-color-success: #41bea3;
  --ion-color-success-rgb: 65, 190, 163;
  --ion-color-success-contrast: var(--ion-color-white);
  --ion-color-success-contrast-rgb: var(--ion-color-white-rgb);
  --ion-color-success-shade: #39a78f;
  --ion-color-success-tint: #54c5ac;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: var(--ion-color-dark);
  --ion-color-warning-contrast-rgb: var(--ion-color-dark-rgb);
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: var(--ion-color-white);
  --ion-color-danger-contrast-rgb: var(--ion-color-white-rgb);
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #00181f;
  --ion-color-dark-rgb: 0, 24, 31;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #00151b;
  --ion-color-dark-tint: #1a2f35;

  --ion-color-medium: #70919a;
  --ion-color-medium-rgb: 112, 145, 154;
  --ion-color-medium-contrast: var(--ion-color-white);
  --ion-color-medium-contrast-rgb: var(--ion-color-white-rgb);
  --ion-color-medium-shade: #638088;
  --ion-color-medium-tint: #7e9ca4;

  --ion-color-light: #eff4f5;
  --ion-color-light-rgb: 239, 244, 245;
  --ion-color-light-contrast: var(--ion-color-medium);
  --ion-color-light-contrast-rgb: var(--ion-color-medium-rgb);
  --ion-color-light-shade: #e8eef0;
  --ion-color-light-tint: #f1f5f6;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: var(--ion-color-primary);
  --ion-color-white-contrast-rgb: var(--ion-color-primary-rgb);
  --ion-color-white-shade: #f0f0f0;
  --ion-color-white-tint: #ffffff;

  --ion-color-info: #3880ff;
  --ion-color-info-rgb: 56, 128, 255;
  --ion-color-info-contrast: #ffffff;
  --ion-color-info-contrast-rgb: 255, 255, 255;
  --ion-color-info-shade: #3171e0;
  --ion-color-info-tint: #4c8dff;

  --ion-color-param-food: #41bea3;
  --ion-color-param-food-rgb: 65, 190, 163;
  --ion-color-param-food-contrast: #ffffff;
  --ion-color-param-food-contrast-rgb: 0, 0, 0;
  --ion-color-param-food-shade: #39a78f;
  --ion-color-param-food-tint: #54c5ac;

  --ion-color-param-mood: #d781b7;
  --ion-color-param-mood-rgb: 215, 129, 183;
  --ion-color-param-mood-contrast: #ffffff;
  --ion-color-param-mood-contrast-rgb: 0, 0, 0;
  --ion-color-param-mood-shade: #bd72a1;
  --ion-color-param-mood-tint: #db8ebe;

  --ion-color-param-energy: #e28d8d;
  --ion-color-param-energy-rgb: 226, 141, 141;
  --ion-color-param-energy-contrast: #ffffff;
  --ion-color-param-energy-contrast-rgb: 0, 0, 0;
  --ion-color-param-energy-shade: #c77c7c;
  --ion-color-param-energy-tint: #e59898;

  .app-theme-sdcom {
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;

    --ion-text-color: #383a48;
    --ion-text-color-rgb: 56, 58, 72;

    --ion-border-color: var(--ion-color-light);
    --ion-item-border-color: var(--ion-color-light);

    --ion-color-step-50: #f5f5f6;
    --ion-color-step-100: #ebebed;
    --ion-color-step-150: #e1e1e4;
    --ion-color-step-200: #d7d8da;
    --ion-color-step-250: #cdced1;
    --ion-color-step-300: #c3c4c8;
    --ion-color-step-350: #b9babf;
    --ion-color-step-400: #afb0b6;
    --ion-color-step-450: #a5a6ad;
    --ion-color-step-500: #9c9da4;
    --ion-color-step-550: #92939a;
    --ion-color-step-600: #888991;
    --ion-color-step-650: #7e7f88;
    --ion-color-step-700: #74757f;
    --ion-color-step-750: #6a6b76;
    --ion-color-step-800: #60616d;
    --ion-color-step-850: #565863;
    --ion-color-step-900: #4c4e5a;
    --ion-color-step-950: #424451;

    --ion-color-primary: #3d49a2;
    --ion-color-primary-rgb: 61, 73, 162;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #36408f;
    --ion-color-primary-tint: #505bab;

    --ion-color-secondary: #ee2b64;
    --ion-color-secondary-rgb: 238, 43, 100;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #d12658;
    --ion-color-secondary-tint: #f04074;

    --ion-color-dark: #383a48;
    --ion-color-dark-rgb: 56, 58, 72;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #31333f;
    --ion-color-dark-tint: #4c4e5a;

    --ion-color-medium: #888991;
    --ion-color-medium-rgb: 136, 137, 145;
    --ion-color-medium-contrast: #383a48;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #787980;
    --ion-color-medium-tint: #94959c;

    --ion-color-light: #f6f7f9;
    --ion-color-light-rgb: 239, 241, 245;
    --ion-color-light-contrast: #383a48;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #f0f1f5;
    --ion-color-light-tint: #f1f2f6;

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: var(--ion-color-primary);
    --ion-color-white-contrast-rgb: var(--ion-color-primary-rgb);
    --ion-color-white-shade: #f0f0f0;
    --ion-color-white-tint: #ffffff;

    --ion-backdrop-color: var(--ion-color-medium);
  }
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}

.ion-color-param-food {
  --ion-color-base: var(--ion-color-param-food);
  --ion-color-base-rgb: var(--ion-color-param-food-rgb);
  --ion-color-contrast: var(--ion-color-param-food-contrast);
  --ion-color-contrast-rgb: var(--ion-color-param-food-contrast-rgb);
  --ion-color-shade: var(--ion-color-param-food-shade);
  --ion-color-tint: var(--ion-color-param-food-tint);
}

.ion-color-param-mood {
  --ion-color-base: var(--ion-color-param-mood);
  --ion-color-base-rgb: var(--ion-color-param-mood-rgb);
  --ion-color-contrast: var(--ion-color-param-mood-contrast);
  --ion-color-contrast-rgb: var(--ion-color-param-mood-contrast-rgb);
  --ion-color-shade: var(--ion-color-param-mood-shade);
  --ion-color-tint: var(--ion-color-param-mood-tint);
}

.ion-color-param-energy {
  --ion-color-base: var(--ion-color-param-energy);
  --ion-color-base-rgb: var(--ion-color-param-energy-rgb);
  --ion-color-contrast: var(--ion-color-param-energy-contrast);
  --ion-color-contrast-rgb: var(--ion-color-param-energy-contrast-rgb);
  --ion-color-shade: var(--ion-color-param-energy-shade);
  --ion-color-tint: var(--ion-color-param-energy-tint);
}

ion-grid {
  &:not(.grid-fixed) {
    width: 100%;
  }
}

.ion-page {
  background: var(--ion-color-light);
}

ion-header {
  border-bottom: var(--app-border-width) solid var(--ion-border-color);

  &.header-md::after {
    display: none;
  }

  &.ion-no-border {
    border: none;
  }

  ion-toolbar {
    @media (min-width: $ion-grid-width-lg) {
      --ion-safe-area-top: var(--ion-padding);
      --ion-safe-area-bottom: var(--ion-padding);
      padding: var(--ion-padding);
    }
  }
}

ion-footer {
  border-top: var(--app-border-width) solid var(--ion-border-color);

  &.footer-md::before {
    display: none;
  }

  &.ion-no-border {
    border: none;
  }

  ion-toolbar {
    @media (min-width: $ion-grid-width-lg) {
      --ion-safe-area-top: var(--ion-padding);
      --ion-safe-area-bottom: var(--ion-padding);
      padding: var(--ion-padding);
    }
  }
}

ion-searchbar {
  min-height: 48px;

  &.sc-ion-searchbar-md-h {
    --box-shadow: none;
    --highlight-background: transparent;
    --placeholder-color: var(--ion-color-medium);
    --placeholder-opacity: 1;
    --icon-color: var(--ion-color-medium);
    --clear-button-color: var(--ion-color-danger);
    --cancel-button-color: var(--ion-color-danger);
    --background: transparent;

    .searchbar-input.sc-ion-searchbar-md {
      padding-left: calc(var(--ion-padding) * 3);
      padding-right: calc(var(--ion-padding) * 3);
    }
  }
}

ion-card {
  --background: var(--ion-color-white);
  font-size: inherit;
  box-shadow: none;
  border-radius: var(--app-border-radius);
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  .app-card-body {
    padding: 0;
    flex: 1;
  }
}

ion-content {
  --background: transparent;
}

ion-list {
  &.list-md {
    padding: 0;
    background: transparent;
  }

  &.app-no-last-line {
    ion-item:last-of-type {
      --border-color: transparent;
    }
  }
}

ion-list-header {
  font-weight: 500;
}

ion-item {
  --background-focused: var(--ion-color-primary);
  --background-focused-opacity: 0.03;
  --background-hover: var(--ion-color-primary);
  --background-hover-opacity: 0.03;
  --highlight-color-focused: var(--ion-color-primary);
  --highlight-color-valid: var(--ion-color-primary);
  --highlight-color-invalid: var(--ion-color-danger);
  --inner-border-width: 0 0 var(--app-border-width) 0;
  --inner-padding-top: calc(var(--ion-padding) / 4);
  --inner-padding-bottom: calc(var(--ion-padding) / 4);
  --detail-icon-color: var(--ion-color-medium);
  --detail-icon-opacity: 1;

  &.item-has-focus {
    --border-color: var(--ion-color-primary);
  }

  &::part(detail-icon) {
    @media (min-width: $ion-grid-width-lg) {
      display: none;
    }
  }

  &.item-lines-none {
    --border-color: transparent;
  }

  ion-label[floating] {
    --color: var(--ion-color-medium) !important;
  }

  ion-input {
    --placeholder-color: var(--ion-color-medium);
    --placeholder-opacity: 1;
  }

  h2,
  h3,
  h4 {
    font-family: inherit;
  }

  p {
    color: var(--ion-color-medium) !important;
    opacity: 1;
  }
}

ion-popover {
  .popover-content.sc-ion-popover-md {
    --box-shadow: none;
    border-radius: var(--app-border-radius);
  }

  ion-select-popover {
    overflow: hidden;
  }

  ion-item {
    --inner-border-width: 0px;
    &.item-radio-checked {
      --color: var(--ion-color-primary);
      font-weight: 500;
    }
  }
}

ion-button {
  --box-shadow: none;
  --padding-start: calc(var(--ion-padding) * 1.5);
  --padding-end: calc(var(--ion-padding) * 1.5);
  letter-spacing: inherit;
  text-transform: none;
  font-size: 1rem;
  font-weight: 500;
  height: 48px;
  margin: 0;

  &:not(.button-round) {
    --border-radius: var(--app-border-radius);
  }

  ion-icon[slot="start"] {
    margin: 0 8px 0 0;
  }
}

.sc-ion-buttons-md-s ion-button {
  font-size: inherit;
  font-weight: 500;
  --padding-top: var(--ion-padding);
  --padding-bottom: var(--ion-padding);
  --padding-start: calc(var(--ion-padding) * 1.2);
  --padding-end: calc(var(--ion-padding) * 1.2);
  height: 48px;

  &:not(.button-round) {
    --border-radius: var(--app-border-radius);
  }

  ion-icon[slot="start"] {
    margin: 0 8px 0 0;
  }
}

ion-segment-button {
  letter-spacing: inherit;
  text-transform: none;
  font-size: 1rem;
  font-weight: 500;
  border-radius: var(--app-border-radius);

  &::part(indicator) {
    display: none;
  }

  &::part(native) {
    ion-ripple-effect {
      border-radius: var(--app-border-radius);
    }
  }
}

ion-select {
  &::part(icon) {
    opacity: 1;
    color: var(--ion-color-medium);
  }
}

ion-chip {
  white-space: nowrap;
  padding-inline: var(--ion-padding);
}

// menu
ion-menu {
  box-shadow: none;
  --border: none;

  &::part(backdrop) {
    opacity: var(--ion-backdrop-opacity);
  }

  &::part(container) {
    box-shadow: none;
  }
}

.md .menu-content-push {
  box-shadow: none;
}

ion-title {
  font-family: "HK Grotesk", sans-serif;
  font-weight: 700;
  padding: 0 var(--ion-padding);
  min-height: 48px;
  letter-spacing: normal;
}

ion-skeleton-text {
  --border-radius: var(--app-border-radius);
  --background: var(--ion-color-light);
}

ion-refresher.refresher-native .refresher-pulling-icon,
ion-refresher.refresher-native .refresher-refreshing-icon {
  background: var(--ion-color-light);
  box-shadow: none;
  border: none;
}

ion-avatar {
  background: var(--ion-color-light);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

ion-alert {
  .alert-title.sc-ion-alert-md {
    font-weight: 700;
  }

  .alert-wrapper.sc-ion-alert-md {
    box-shadow: none;
    border-radius: var(--app-border-radius);
  }

  .alert-button.sc-ion-alert-md {
    text-transform: none;
    letter-spacing: normal;
    border-radius: var(--app-border-radius);
    font-size: 1rem;
  }
  .alert-message.sc-ion-alert-md,
  .alert-input-group.sc-ion-alert-md {
    color: var(--ion-text-color);
  }
}

ion-toast {
  --box-shadow: none;
  --max-width: 500px;
  --border-radius: var(--app-border-radius);
  width: 500px;
  right: 0px;
  left: auto;
  max-width: 100%;
  @media (min-width: $ion-grid-width-lg) {
    --ion-safe-area-bottom: var(--ion-margin);
    --end: var(--ion-margin);
  }
}

ion-modal {
  &.sc-ion-modal-md-h {
    --height: 100%;
    --width: 100%;
    padding-top: calc(var(--ion-padding) * 2);

    @media (min-width: $ion-grid-width-lg) {
      --max-width: 700px;
      --border-radius: var(--app-border-radius);
      padding: calc(var(--ion-padding) * 2);
      justify-content: flex-end;
    }

    .modal-wrapper {
      box-shadow: none;
      border-top-left-radius: var(--app-border-radius);
      border-top-right-radius: var(--app-border-radius);
    }
  }
}

ion-backdrop {
  opacity: var(--ion-backdrop-opacity);
  --backdrop-opacity: var(--ion-backdrop-opacity);
}

ion-back-button {
  &::part(text) {
    text-transform: capitalize;
    margin-top: 2px;
  }
}

ion-textarea {
  --placeholder-color: var(--ion-color-medium) !important;
  --placeholder-opacity: 1 !important;
}

.ion-text-line {
  white-space: pre-line;
}
