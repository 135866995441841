@import "/libs/shared/src/styles/variables.scss";

// html
body {
  color: var(--ion-text-color);
  background-color: var(--ion-color-light);
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  --ion-font-family: "HK Grotesk", "Roboto", sans-serif;
  font-family: var(--ion-font-family);
  font-weight: 700;
}

h4 {
  font-size: 20px;
}

b,
strong {
  font-weight: 500;
}

a {
  text-decoration: none;
  // font-weight: 500;
  color: var(--ion-color-secondary);
}

hr {
  background: var(--ion-border-color);
  height: var(--app-border-width);
  margin: var(--ion-margin) 0;
}

section {
  padding: var(--ion-padding);
}

/*
* Add to an ion-avatar when using inside a toolbar and slot start. This will
* place it roughly where the menu button would go.
*/
.app-feature-avatar {
  margin-left: 4px;
  height: 44px;
  width: 44px;
}

.app-input {
  --border-width: var(--app-border-width);
  --border-radius: var(--app-border-radius);
  --background: white;
  --border-color: var(--ion-color-light);
  --inner-border-width: 0 !important;
  --highlight-background: transparent;
  margin-left: var(--ion-margin);
  margin-right: var(--ion-margin);

  ion-avatar {
    background: transparent;
  }
}

ion-list {
  .app-input + .app-input {
    margin-top: var(--ion-margin);
  }
}

.app-menu-overlay {
  --width: 100%;
  --max-width: var(--ion-grid-width-md);
}

// ionic
ion-toolbar {
  &.app-padding-lg {
    @media (min-width: $ion-grid-width-lg) {
      --ion-safe-area-top: var(--ion-padding);
      --ion-safe-area-bottom: var(--ion-padding);
      --ion-safe-area-start: var(--ion-padding);
      --ion-safe-area-end: var(--ion-padding);
    }
  }
}

ion-content {
  &.app-padding-lg {
    @media (min-width: $ion-grid-width-lg) {
      --padding-top: var(--ion-padding);
      --padding-bottom: var(--ion-padding);
      --padding-start: var(--ion-padding);
      --padding-end: var(--ion-padding);
    }
  }
}

ion-button.in-toolbar.app-toolbar-button {
  @media (max-width: $ion-grid-width-lg) {
    ion-label {
      display: none;
    }

    ion-icon {
      margin-inline-end: 0;
    }

    &.item-has-focus {
      &::part(native) {
        border-color: var(--ion-color-primary);
        border-width: var(--app-border-width);
      }
    }
  }
}

ion-item {
  &.app-input {
    --inner-border-width: 0 !important;
    margin: var(--ion-margin);
    --highlight-background: transparent;

    ion-input {
      --padding-bottom: 12px;
    }

    ion-label {
      --color: var(--ion-color-medium) !important;
    }

    &.item-has-value .label-floating.sc-ion-label-md-h,
    &.item-has-focus .label-floating.sc-ion-label-md-h {
      transform: translateY(50%) scale(0.75) !important;
    }

    &.item-textarea {
      ion-textarea {
        --padding-top: 0 !important;
      }

      .label-floating.sc-ion-label-md-h {
        transform: translateY(96%) !important;
      }

      &.item-has-value .label-floating.sc-ion-label-md-h,
      &.item-has-focus .label-floating.sc-ion-label-md-h {
        transform: translateY(50%) scale(0.75) !important;
      }
    }
  }
}

ion-searchbar {
  &.app-title-searchbar {
    @media (min-width: $ion-grid-width-lg) {
      max-width: 300px;
    }
  }
}

.app-text-line {
  white-space: pre-line !important;
}
